<template>
  <!--begin::Modal - Create App-->
  <div class="card mb-5 mb-xl-10" v-if="agentDetail.address">
    <div class="card-header p-8 pb-0">
        <h2 v-if="agentId===0">Create agent</h2>
        <h2 v-else>Agent details</h2>
    </div>
    <div class="card-body pt-9 pb-0">
        <el-form
            id="modal_agent_detail_form"
            class="form"
            :model="agentDetail"
            ref="formRef"
            :rule="rules"
        >
            <div class="modal-body py-lg-10 px-lg-10">
                <div class="row mb-6">
                    <label for="agent_name" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Comany Name</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="companyName">
                            <input 
                                type="text" 
                                name="companyName" 
                                class="form-control form-control-solid" 
                                placeholder="Company Name" 
                                v-model="agentDetail.company_name"/>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_name" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Name</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="name">
                            <input 
                                type="text" 
                                id="agent_name" 
                                name="name" 
                                class="form-control form-control-solid" 
                                placeholder="Name" 
                                v-model="agentDetail.name"/>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_phone" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Phone</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="phone">
                            <input 
                                type="tel" 
                                class="form-control form-control-solid" 
                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" 
                                name="phone" 
                                placeholder="Phone"
                                v-model="agentDetail.phone" />
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_email" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Email</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="email">
                            <input 
                                type="email" 
                                name="email" 
                                class="form-control form-control-solid" 
                                placeholder="Email" 
                                v-model="agentDetail.email"/>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_address" class="col-lg-3 col-form-label fw-bold fs-6">
                        Address
                    </label>
                    <div class="row col-lg-9">
                        <div class="col-lg-12 fv-row">
                            <el-form-item prop="address1">
                                <input 
                                    name="address1"
                                    type="text" 
                                    class="form-control form-control-solid" 
                                    placeholder="Street Address" 
                                    v-model="agentDetail.address.address1"/>
                            </el-form-item>
                        </div>
                        <div class="col-lg-12 fv-row" style="margin-top:5px;">
                            <el-form-item prop="address2">
                                <input 
                                    name="address2"
                                    type="text" 
                                    class="form-control form-control-solid" 
                                    placeholder="Address2" 
                                    v-model="agentDetail.address.address2"/>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6 fv-row" style="margin-top:5px;">
                            <el-form-item prop="city">
                                <input 
                                    name="city"
                                    type="text" 
                                    class="form-control form-control-solid" 
                                    placeholder="City" 
                                    v-model="agentDetail.address.city"/>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6 fv-row" style="margin-top:5px;">
                            <el-form-item prop="state">
                                <input 
                                    name="state"
                                    type="text" 
                                    class="form-control form-control-solid" 
                                    placeholder="State" 
                                    v-model="agentDetail.address.state"/>
                            </el-form-item>
                        </div>
                        <div class="col-lg-3 fv-row" style="margin-top:5px;">
                            <el-form-item prop="zipcode">
                                <input 
                                    name="zipcode"
                                    type="text" 
                                    class="form-control form-control-solid" 
                                    placeholder="Zip Code" 
                                    v-model="agentDetail.address.zipcode"/>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_email" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Commission Rate</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="commisionRate">
                            <input 
                                type="number" 
                                name="commisionRate" 
                                class="form-control form-control-solid" 
                                placeholder="Commision Rate" 
                                v-model="agentDetail.commission_rate"/>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                        Active
                    </label>
                    <div class="col-lg-9 d-flex align-items-center">
                        <div class="form-check form-check-solid form-switch fv-row">
                            <el-form-item prop="isActive">
                                <input class="form-check-input w-45px h-30px" name="isActive" type="checkbox" id="isActive" v-model="agentDetail.is_active" />
                                <label class="form-check-label" for="isActive"></label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                        Student
                    </label>
                    <div class="col-lg-9 d-flex align-items-center">
                        <StudentWidget class="col-md-12"
                            :agentStudents="agentDetail.students"
                            @updateStudents="updateStudents" >
                        </StudentWidget>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="modal-footer flex-center">
            <button
              type="button"
              id="modal_agent_detail_submit"
              class="btn btn-danger"
              ref="deleteButton"
              @click="deleteAgent"
              v-if="agentId != 0"
            >
              <span class="indicator-label"> Delete </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <button
              type="button"
              id="modal_agent_detail_submit"
              class="btn btn-primary"
              ref="submitButton"
              @click="submit"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
        </div>
      </div>
    </div>
    <div class="card mb-5 mb-xl-10 p-10" v-if="agentId != 0">
        <MemoWidget 
            memoType="Agent"
            v-bind:memoTypeId="agentId"
            pageSize=5 />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onBeforeMount, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import StudentWidget from "@/views/management/widgets/Students.vue"
import MemoWidget from "@/views/widgets/Memo.vue"
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import { AgentDetails } from "@/types/AgentModel";
import store from "@/store";
import { Address } from "@/types/AddressModel";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessageBox } from "element-plus";

export default defineComponent({
    name: "agent-detail-modal",
    components: {
        StudentWidget,
        MemoWidget
    },
    setup() {
        const route = useRoute();
        const agentId = +route.params.id; 
        const formRef = ref<null | HTMLFormElement>(null);
        const submitButton = ref<HTMLElement | null>(null);
        const agentDetail = reactive({} as any);
        const user = store.getters.currentUser;
        
        const getAgent = async () => {
            if(agentId == 0){
                Object.assign(agentDetail, new AgentDetails());
            }
            else{
                await new Promise((resolve, reject) => { 
                    ApiService.setHeader();           
                    ApiService.get("agent/"+agentId)
                        .then(({ data }) => {
                            if(data.data){
                                Object.assign(agentDetail,data.data);
                            }
                            else{
                                Object.assign(agentDetail, new AgentDetails());
                            }

                            if(!agentDetail.address){
                                agentDetail.address = new Address();
                            }
                            agentDetail.user_id= user.id;
                            resolve(data.data);
                        })
                        .catch(({ response }) => {
                            reject(new Error ('getAgents error => '+response.data.errors));
                        });
                });
            }
        }

        const rules = ref({
            name: [
                {
                required: true,
                message: "Please input name",
                trigger: "change",
                },
            ],
            email: [
                {
                required: true,
                message: "Please input email",
                trigger: "change",
                },
            ],
            phone: [
                {
                required: true,
                message: "Please input phone",
                trigger: "change",
                },
            ],
        });

        const updateStudents = (selectedStudents) =>{
            agentDetail.students = selectedStudents;
        }

        const submit = () => {
            submitButton.value?.setAttribute("data-kt-indicator", "on");
            if(agentId === 0){
                ApiService.setHeader();
                agentDetail.user_id = user.id;
                ApiService.post("agent", agentDetail)
                    .then(() => {
                        Swal.fire({
                        text: "Agent information has been successfully saved!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(() => {
                            router.push({ name: "agent"});
                        });
                    })
                    .catch(({ response }) => {
                        console.log('createagent error => '+response.data.errors);
                        Swal.fire({
                        text: "Failed to create the new agent",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
            else
            {
                ApiService.setHeader();
                ApiService.update("agent", agentId, agentDetail)
                    .then(() => {
                        Swal.fire({
                            text: "Agent is updated successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "agent"});
                            });
                    })
                    .catch(({ response }) => {
                        console.log('update agent error => '+response.data.errors);
                        Swal.fire({
                        text: "Failed to update the agent",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
        };

        onBeforeMount(async () => {
            await getAgent();
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(agentId != 0 ? "Agent Detail": "Create Agent", ["Management", "Agent"]);
        });

        const deleteAgent = () => {
            submitButton.value?.setAttribute("data-kt-indicator", "on");
            if(agentId != 0){
                ElMessageBox.confirm('Are you sure to delete this record?')
                .then(() => {
                        ApiService.setHeader();
                        ApiService.delete("agent/"+agentId)
                            .then(() => {
                                Swal.fire({
                                text: "Agent is successfully deleted!",
                                icon: "success",
                                buttonsStyling: false,
                                confirmButtonText: "Ok, got it!",
                                customClass: {
                                    confirmButton: "btn fw-bold btn-light-primary",
                                    },
                                }).then(() => {
                                    router.push({ name: "agent"});
                                });
                            })
                            .catch(({ response }) => {
                                console.log('deleteAgent error => '+response.data.errors);
                                Swal.fire({
                                text: "Failed to delete the agent",
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: "Ok, got it!",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                });
                            })
                            .finally(() =>{
                                submitButton.value?.removeAttribute("data-kt-indicator");
                            });
                });
            }
        }

        return {
            submit,
            formRef,
            rules,
            agentDetail,
            agentId,
            updateStudents,
            deleteAgent
        };
    },
});
</script>
