
import { defineComponent, ref, reactive, onBeforeMount, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import StudentWidget from "@/views/management/widgets/Students.vue"
import MemoWidget from "@/views/widgets/Memo.vue"
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import { AgentDetails } from "@/types/AgentModel";
import store from "@/store";
import { Address } from "@/types/AddressModel";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessageBox } from "element-plus";

export default defineComponent({
    name: "agent-detail-modal",
    components: {
        StudentWidget,
        MemoWidget
    },
    setup() {
        const route = useRoute();
        const agentId = +route.params.id; 
        const formRef = ref<null | HTMLFormElement>(null);
        const submitButton = ref<HTMLElement | null>(null);
        const agentDetail = reactive({} as any);
        const user = store.getters.currentUser;
        
        const getAgent = async () => {
            if(agentId == 0){
                Object.assign(agentDetail, new AgentDetails());
            }
            else{
                await new Promise((resolve, reject) => { 
                    ApiService.setHeader();           
                    ApiService.get("agent/"+agentId)
                        .then(({ data }) => {
                            if(data.data){
                                Object.assign(agentDetail,data.data);
                            }
                            else{
                                Object.assign(agentDetail, new AgentDetails());
                            }

                            if(!agentDetail.address){
                                agentDetail.address = new Address();
                            }
                            agentDetail.user_id= user.id;
                            resolve(data.data);
                        })
                        .catch(({ response }) => {
                            reject(new Error ('getAgents error => '+response.data.errors));
                        });
                });
            }
        }

        const rules = ref({
            name: [
                {
                required: true,
                message: "Please input name",
                trigger: "change",
                },
            ],
            email: [
                {
                required: true,
                message: "Please input email",
                trigger: "change",
                },
            ],
            phone: [
                {
                required: true,
                message: "Please input phone",
                trigger: "change",
                },
            ],
        });

        const updateStudents = (selectedStudents) =>{
            agentDetail.students = selectedStudents;
        }

        const submit = () => {
            submitButton.value?.setAttribute("data-kt-indicator", "on");
            if(agentId === 0){
                ApiService.setHeader();
                agentDetail.user_id = user.id;
                ApiService.post("agent", agentDetail)
                    .then(() => {
                        Swal.fire({
                        text: "Agent information has been successfully saved!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(() => {
                            router.push({ name: "agent"});
                        });
                    })
                    .catch(({ response }) => {
                        console.log('createagent error => '+response.data.errors);
                        Swal.fire({
                        text: "Failed to create the new agent",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
            else
            {
                ApiService.setHeader();
                ApiService.update("agent", agentId, agentDetail)
                    .then(() => {
                        Swal.fire({
                            text: "Agent is updated successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "agent"});
                            });
                    })
                    .catch(({ response }) => {
                        console.log('update agent error => '+response.data.errors);
                        Swal.fire({
                        text: "Failed to update the agent",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
        };

        onBeforeMount(async () => {
            await getAgent();
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(agentId != 0 ? "Agent Detail": "Create Agent", ["Management", "Agent"]);
        });

        const deleteAgent = () => {
            submitButton.value?.setAttribute("data-kt-indicator", "on");
            if(agentId != 0){
                ElMessageBox.confirm('Are you sure to delete this record?')
                .then(() => {
                        ApiService.setHeader();
                        ApiService.delete("agent/"+agentId)
                            .then(() => {
                                Swal.fire({
                                text: "Agent is successfully deleted!",
                                icon: "success",
                                buttonsStyling: false,
                                confirmButtonText: "Ok, got it!",
                                customClass: {
                                    confirmButton: "btn fw-bold btn-light-primary",
                                    },
                                }).then(() => {
                                    router.push({ name: "agent"});
                                });
                            })
                            .catch(({ response }) => {
                                console.log('deleteAgent error => '+response.data.errors);
                                Swal.fire({
                                text: "Failed to delete the agent",
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: "Ok, got it!",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                });
                            })
                            .finally(() =>{
                                submitButton.value?.removeAttribute("data-kt-indicator");
                            });
                });
            }
        }

        return {
            submit,
            formRef,
            rules,
            agentDetail,
            agentId,
            updateStudents,
            deleteAgent
        };
    },
});
