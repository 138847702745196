
import { defineComponent, ref, onBeforeMount, computed, SetupContext, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "students-widget",
  components: {
      Multiselect
  },
  props: {
      agentStudents: []
  },
  emits: ['updateStudents'],
  setup(props, {emit} ) {
    const students = ref([] as any);
    const activeStudents = ref([] as any);
    const selectedStudents = ref([] as any);
    const studentsData = computed(() => {
        const data = activeStudents.value != undefined && activeStudents.value.length > 0 ? activeStudents.value.map(x=>{
          return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")", image:x.image}}) :[];
          return data;
    });

    students.value = [];

    watch(() => props.agentStudents, (cur, prev)=>{
        if(props.agentStudents){
            students.value = props.agentStudents;
        }
        else{
            students.value = [];
        }
        getActiveStudents();
    })

    const emptyProfileImage = '/media/avatars/blank.png';

    const availableStudentList = ref({
        mode: "tags",
        placeholder: "Select student",
        searchable : true,
        options: studentsData,
    });
    
    const getActiveStudents = async() => {
        ApiService.setHeader();
        await ApiService.get("student/status/Active")
            .then(({ data }) => {
                activeStudents.value = data.data.filter(x=> x.agent_id == null && (!students.value || students.value?.filter(y=> y.id == x.id).length ===0));
            })
            .catch(({ response }) => {
            console.log('getStudents error => '+response.data.errors);
            });
    }

    const addToStudentList = () => {
        for(let index in selectedStudents.value){
            let id = selectedStudents.value[index];
            var student = activeStudents.value.filter(x=>x.id == id)[0];

            if(!students.value){
                students.value = [];
            }

            students.value.unshift({first_name :student.first_name, last_name : student.last_name, image : student.image, id : student.id, student_id : student.student_id });
            activeStudents.value = activeStudents.value.filter(x=>x.id != id);
        }
        selectedStudents.value = [];
        emit('updateStudents', students);
    }

    const deleteFromStudentList = (student) =>{
        activeStudents.value.push({
            first_name :student.first_name, 
            last_name : student.last_name,
            image : student.image, 
            id : student.id,
            student_id : student.student_id
        }); 

        students.value = students.value.filter(x=>x.id != student.id);
        emit('updateStudents', students);
    }

    onBeforeMount(async()=>{
        students.value = props.agentStudents;
        console.log(props.agentStudents);
        await getActiveStudents();
    });

    return {
      students,
      availableStudentList,
      addToStudentList,
      deleteFromStudentList,
      selectedStudents,
      emptyProfileImage
    };
  },
});
