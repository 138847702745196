import { Address } from "./AddressModel";

export class AgentDetails {
  constructor (){
    this.address = new Address();
  }

  id: number;
  name: string;
  company_name:string;
  phone: string;
  email: string;
  commission_rate: number;
  address : Address;
  is_active: boolean;
  user_id: number;
}

